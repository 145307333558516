/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
const langMap = {
    'ro': ''
}

const parentMap = {
    'service': {
        'ro': 'servicii'
    },
    'subscription': {
        'ro': 'abonamente'
    },
    'article': {
        'ro': 'noutati'
    },
    'team_member': {
        'ro': 'medici'
    }
}

const mapLang = (doc) => langMap[doc.lang] ?? doc.lang
const mapParent = (doc) => parentMap[doc.type][doc.lang] ?? ''

exports.linkResolver = (doc) => {
    switch (doc.type) {
        case 'homepage': {
            return `${mapLang(doc)}/`
        }

        case 'services_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        case 'service': {
            return `${mapLang(doc)}/${mapParent(doc)}/${doc.uid}/`
        }

        case 'subscriptions_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        case 'subscription': {
            return `${mapLang(doc)}/${mapParent(doc)}/${doc.uid}/`
        }

        case 'team_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        case 'team_member': {
            return `${mapLang(doc)}/${mapParent(doc)}/${doc.uid}/`
        }

        case 'articles_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        case 'article': {
            return `${mapLang(doc)}/${mapParent(doc)}/${doc.uid}/`
        }

        case 'contact_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        case 'static_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        case 'about_page': {
            return `${mapLang(doc)}/${doc.uid}/`
        }

        default:
            return `${mapLang(doc)}/`
    }
}

exports.mapLang = mapLang
exports.mapParent = mapParent