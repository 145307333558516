import React from "react"
import Container from '@mui/material/Container';
import { Box, Grid, Link, Typography } from "@mui/material";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";

import Logo from "./Logo";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import YoutubeIcon from "./icons/YoutubeIcon";
import { useActiveDoc } from "../contexts/ActiveDocProvider";
import MenuLink from "./menus/MenuLink";
import theme from '../styles/theme';
import TikTokIcon from "./icons/TikTokIcon";


const Footer = () => {
    const { activeDoc } = useActiveDoc()

    const data = useStaticQuery(query)

    const mainMenu = data.mainMenu.edges
        .filter(edge => edge.node.lang === activeDoc.lang)
        .map(r => r.node)
        .pop()

    const mainMenuItems = mainMenu.data.body

    const footerMenu = data.footerMenu.edges
        .filter(edge => edge.node.lang === activeDoc.lang)
        .map(r => r.node)
        .pop()

    const footerMenuItems = footerMenu.data.body

    return (
        <>
            <br />
            <Box sx={{
                paddingTop: 2,
                paddingBottom: 2,
                borderTop: '1px solid #F2F4F7',
                borderBottom: '1px solid #F2F4F7',
            }}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="column"
                        spacing={2}>
                        <Grid item>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item>
                                    <Logo />
                                </Grid>
                                <Grid item>
                                    <Grid container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center">
                                        <Grid item>
                                            <Box style={{ display: 'block' }} component="a" name="Facebook" target="_blank" href="https://www.facebook.com/DentalHygieneCenter">
                                                <FacebookIcon sx={{
                                                    color: '#CE2227',
                                                    fontSize: '22px'
                                                }} />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box style={{ display: 'block' }} component="a" name="Instagram" target="_blank" href="https://www.instagram.com/dental_hygiene_center/">
                                                <InstagramIcon sx={{
                                                    color: '#CE2227',
                                                    fontSize: '24px'
                                                }} />
                                            </Box>

                                        </Grid>
                                        <Grid item>
                                            <Box style={{ display: 'block' }} component="a" name="TikTok" target="_blank" href="https://www.tiktok.com/@dentalhygienecenter">
                                                <TikTokIcon sx={{
                                                    color: '#CE2227',
                                                    fontSize: '18px'
                                                }} />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box style={{ display: 'block' }} component="a" name="YouTube" target="_blank" href="https://www.youtube.com/channel/UCeCWny8CT4OBuX5ieLBaPPA">
                                                <YoutubeIcon sx={{
                                                    color: '#CE2227',
                                                    fontSize: '32px'
                                                }} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                spacing={3}
                                justifyContent="center"

                                sx={{
                                    height: "100%",
                                }}
                            >
                                {mainMenuItems.map(menuItem => (
                                    <Grid item key={menuItem.id}>
                                        <MenuLink to={menuItem.primary.url} sx={{
                                            color: theme.palette.text.primary,
                                            fontWeight: 500,
                                            paddingBottom: 1,
                                            marginBottom: 1
                                        }}>
                                            {menuItem.primary.label}
                                        </MenuLink>

                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography
                                component={Link}
                                href="tel:+40773856287"
                                sx={{
                                    textAlign: 'center',
                                    display: 'block',
                                    textDecoration: 'none',
                                    color: '#635F5D',
                                    '&:hover': {
                                        color: '#710B0E'
                                    }
                                }}>
                                Telefon: <Typography component="span">0773 856 287</Typography>
                            </Typography>
                            <Typography
                                component={Link}
                                href="mailto:receptie@dentalhygienecenter.ro"
                                sx={{
                                    textAlign: 'center',
                                    display: 'block',
                                    textDecoration: 'none',
                                    color: '#635F5D',
                                    '&:hover': {
                                        color: '#710B0E'
                                    }
                                }}>
                                E-Mail: <Typography component="span">receptie@dentalhygienecenter.ro</Typography>
                            </Typography>
                            <Typography sx={{
                                textAlign: 'center',
                                color: '#635F5D'
                            }}>
                                Luni - Duminică<br />
                                09:00 - 21:00
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{
                paddingTop: 2,
                paddingBottom: 4,
                textAlign: 'center'
            }}>
                <Typography
                    component="span"
                    sx={{ display: 'inline-block' }}>
                    Copyright &copy; {new Date().getFullYear()}
                </Typography>
                {footerMenuItems.map(menuItem => <Typography
                    sx={{
                        display: {
                            xs: 'block',
                            md: 'inline-block'
                        },
                        marginLeft: 1,
                        textAlign: 'center',
                        textDecoration: 'none',
                        color: '#635F5D',
                        '&:hover': {
                            color: '#710B0E'
                        }
                    }}
                    key={`footer-menu-item-${menuItem.id}`}
                    component={GatsbyLink}
                    to={menuItem.primary.url}>
                    {menuItem.primary.label}
                </Typography>)}
            </Box>
        </>
    )
}

const query = graphql`
  query FooterMainMenuQuery {
     mainMenu: allPrismicMainMenu {
      edges {
        node {
          type
          lang
          data {
            body {
              ... on PrismicMainMenuDataBodyMenuItem {
                id
                primary {
                  label
                  url
                }
                items {
                  sub_item_label
                  sub_item_url
                }
              }
            }
          }          
        }
      }
    }

    footerMenu: allPrismicFooterMenu {
        edges {
            node {
              type
              lang
              data {
                body {
                  ... on PrismicFooterMenuDataBodyMenuItem {
                    id
                    primary {
                      label
                      url
                    }
                    items {
                      sub_item_label
                      sub_item_url
                    }
                  }
                }
              }          
            }
          }
    }
  }
`

export default Footer

