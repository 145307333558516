import React from "react"

import SvgIcon from '@mui/material/SvgIcon';

const PinIcon = props => (
    <SvgIcon {...props} viewBox="0 0 32 32">

        <path d="M16 24.6334C18.2 22.8556 19.8613 21.1445 20.984 19.5C22.1058 17.8556 22.6667 16.3112 22.6667 14.8667C22.6667 13.6223 22.4391 12.5609 21.984 11.6827C21.528 10.8054 20.9667 10.0943 20.3 9.54937C19.6333 9.00537 18.9111 8.61115 18.1333 8.36671C17.3556 8.12226 16.6444 8.00004 16 8.00004C15.3556 8.00004 14.6444 8.12226 13.8667 8.36671C13.0889 8.61115 12.3667 9.00537 11.7 9.54937C11.0333 10.0943 10.4724 10.8054 10.0173 11.6827C9.56134 12.5609 9.33334 13.6223 9.33334 14.8667C9.33334 16.3112 9.89423 17.8556 11.016 19.5C12.1387 21.1445 13.8 22.8556 16 24.6334ZM16 27.5334C15.8 27.5334 15.6111 27.5054 15.4333 27.4494C15.2556 27.3943 15.0889 27.3111 14.9333 27.2C12.1556 25.0445 10.0836 22.9445 8.71734 20.9C7.35023 18.8556 6.66667 16.8445 6.66667 14.8667C6.66667 13.2889 6.95023 11.9054 7.51734 10.716C8.08356 9.5276 8.81112 8.53337 9.70001 7.73337C10.5889 6.93337 11.5889 6.33337 12.7 5.93337C13.8111 5.53337 14.9111 5.33337 16 5.33337C17.0889 5.33337 18.1889 5.53337 19.3 5.93337C20.4111 6.33337 21.4111 6.93337 22.3 7.73337C23.1889 8.53337 23.9169 9.5276 24.484 10.716C25.0502 11.9054 25.3333 13.2889 25.3333 14.8667C25.3333 16.8445 24.6498 18.8556 23.2827 20.9C21.9164 22.9445 19.8444 25.0445 17.0667 27.2C16.9333 27.3111 16.7724 27.3943 16.584 27.4494C16.3947 27.5054 16.2 27.5334 16 27.5334ZM16 17.3334C16.7333 17.3334 17.3613 17.072 17.884 16.5494C18.4058 16.0276 18.6667 15.4 18.6667 14.6667C18.6667 13.9334 18.4058 13.3054 17.884 12.7827C17.3613 12.2609 16.7333 12 16 12C15.2667 12 14.6391 12.2609 14.1173 12.7827C13.5947 13.3054 13.3333 13.9334 13.3333 14.6667C13.3333 15.4 13.5947 16.0276 14.1173 16.5494C14.6391 17.072 15.2667 17.3334 16 17.3334Z" fill="#282421" />



    </SvgIcon>
)

export default PinIcon
