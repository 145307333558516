import { Box, Grid, Typography } from "@mui/material"
import React from "react"
// import { useScheduler } from "../../contexts/SchedulerProvider"
import { useTranslation } from "../../contexts/TranslationProvider"
import SimpleButton from "../buttons/SimpleButton"
import PhoneIcon from "../icons/PhoneIcon"


const ContactForm = ({ children, sx = [], ...props }) => {
    const { t } = useTranslation()
    return (
        <Box sx={{
            borderRadius: '10px',
            background: '#F5F5F5',
            padding: 2
        }}>
            <Grid
                spacing={1}
                container
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Box
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
                    >
                        <PhoneIcon />
                        <Typography
                            component="a"
                            href="tel:+40773856287"
                            sx={{
                                ml: 1,
                                flex: 1,
                                width: '100px',
                                color: '#282421',
                                textDecoration: 'none',
                                fontSize: '14px'
                            }}>
                            0773856287
                        </Typography>
                        {/*
                        <InputBase
                            sx={{
                                ml: 1,
                                flex: 1,
                                width: '100px',
                                color: '#282421',
                            }}
                            placeholder="07xxxxxxxx"
                            inputProps={{ 'aria-label': 'phone number' }}
                        />
                        */}
                    </Box>
                </Grid>
                <Grid item>
                    <SimpleButton
                        href="https://www.planfy.com/booking-widget/medical-advisor-care-srl"
                        sx={{
                            backgroundColor: '#CE2227',
                            color: '#FFF',
                            height: '38px',
                            paddingLeft: 2,
                            paddingRight: 2,
                            fontSize: '12px',
                            "&:hover": {
                                backgroundColor: '#FFEAEA',
                                color: 'secondary.main'
                            }
                        }}>{t('scheduleNow')}</SimpleButton>
                </Grid>
            </Grid>

        </Box>
    )
}

export default ContactForm
