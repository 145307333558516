import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    typography: {
        fontFamily: [
            "Lexend Variable",
            "Arial",
        ].join(","),
        fontWeightRegular: 400,
        fontWeightBold: 600,
        fontSize: 14,
        h1: {
            fontWeight: 600,
            fontSize: 50
        },
        h2: {
            fontWeight: 700,
            fontSize: 30
        },
        h3: {
            fontWeight: 500,
            fontSize: 22
        },
        h4: {
            fontWeight: 500,
            fontSize: 18,
        },
        h5: {
            fontWeight: 700,
            fontSize: 20,

        },
        h6: {
            fontWeight: 700,
            fontSize: 18,
        },
        body1: {
            fontSize: 16,
            fontWeight: 300
        }

    },
    palette: {
        primary: {
            main: "#B9151A",
        },
        secondary: {
            main: "#CE2227",
            second: '#FFEAEA'
        },

        background: {
            default: "#FFFFFF",
        },
        text: {
            primary: '#282421'
        }
    },
})

theme = responsiveFontSizes(theme);


export default theme
