module.exports = {
  ro: {
    ogLang: "ro_RO",
    hrefLang: "ro",
    readingTime: "timp de citire",
    min: "min",
    readMore: 'Citește mai mult',
    seeMore: 'Vezi mai mult',
    homePage: 'Acasă',
    servicesPage: 'Servicii',
    subscriptionsPages: 'Abonamente',
    articlesPage: 'Noutăți',
    teamPage: 'Medici',
    scheduleNow: 'Programeaza-te online',
    callNow: 'Sună',
    seeAlso: 'Vezi și...',
    chooseLocation: 'Alege locația cabinetului',
    schedule: 'Programează-te',
    articlesWrittenBy: 'Articole scrise de',
    paymentSuccess: 'Îți mulțumim! Plata a fost efectuată cu success. Te vom contacta pentru mai multe detalii!',
    paymentCancel: 'Plata a fost anulată sau o a apărut o problemă. Te rugăm sa încerci din nou!',
    buyNow: 'Cumpără acum pentru',
    buy: 'Cumpără'
  }
}
