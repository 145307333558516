import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { useMainMenu } from "../../contexts/MainMenuProvider"
import theme from '../../styles/theme';
import "../../styles/menu-burger.scss"
import MenuLink from './MenuLink';
import ContactForm from '../forms/ContactForm';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import YoutubeIcon from '../icons/YoutubeIcon';

import { useActiveDoc } from "../../contexts/ActiveDocProvider";
import TikTokIcon from '../icons/TikTokIcon';

const MobileMenu = () => {
  const [open, setOpen] = useState(false)
  const { activeDoc } = useActiveDoc()

  const data = useStaticQuery(query)

  const mainMenu = data.mainMenu.edges
    .filter(edge => edge.node.lang === activeDoc.lang)
    .map(r => r.node)
    .pop()

  const items = mainMenu.data.body

  const { openMenu, closeMenu } = useMainMenu()

  const toggleMobileMenu = () => {
    if (open) {
      closeMenu()
    } else {
      openMenu()
    }

    setOpen(!open)
  }

  return (
    <>
      <Box
        component="nav"
        variant="dense"
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          pointerEvents: "auto",
          display: 'flex'
        }}
      >
        <Box>
          <input className="menu-trigger hidden" id="togglenav" type="checkbox" checked={open} onClick={toggleMobileMenu} onChange={toggleMobileMenu} />
          <label className="burger-wrapper" htmlFor="togglenav">
            <div className="hamburger"></div>
          </label>
        </Box>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit timeout={300}>
          <Container
            maxWidth={false}
            sx={{
              position: 'fixed',
              backgroundColor: 'common.white',
              left: 0,
              top: '66px',
              paddingTop: 2,
              width: '100%',
              height: 'calc(100vh - 66px)',
              zIndex: 999,
              userSelect: 'none'
            }}>

            <Container maxWidth="lg" disableGutters sx={{ height: '100%' }}>
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%",
                }}
              >
                {items.map(menuItem => (
                  <Grid item key={menuItem.id} xs={12}>
                    <Box sx={{
                      display: 'block',
                      textAlign: 'center',
                      marginBottom: 1
                    }}>
                      <MenuLink
                        onClick={toggleMobileMenu}
                        to={menuItem.primary.url}
                        sx={{
                          borderBottom: '1px solid #CE2227',
                          color: theme.palette.text.primary,
                          fontWeight: 500,
                          paddingBottom: 1,
                          marginBottom: 1,
                          display: 'inline',
                          fontSize: '16px'
                        }}>
                        {menuItem.primary.label}
                      </MenuLink>
                    </Box>
                    {menuItem.items.length > 0 && (
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="stretch"
                        justifyContent="center">
                        {menuItem.items.map((menuSubItem, idx) => (
                          <Grid item key={`main-menu-sub-item-${menuItem.id}-${idx}`}>
                            <MenuLink
                              onClick={toggleMobileMenu}
                              to={menuSubItem.sub_item_url}
                              sx={{
                                fontSize: '18px'
                              }}>
                              {menuSubItem.sub_item_label}
                            </MenuLink>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                ))}

                <Grid item>
                  <ContactForm />
                </Grid>
                <Grid item>
                  <Grid container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center">
                    <Grid item>
                      <Box component="a" name="Facebook" target="_blank" href="https://www.facebook.com/DentalHygieneCenter">
                        <FacebookIcon sx={{
                          color: '#CE2227',
                          fontSize: '28px'
                        }} />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box component="a" name="Instagram" target="_blank" href="https://www.instagram.com/dental_hygiene_center/">
                        <InstagramIcon sx={{
                          color: '#CE2227',
                          fontSize: '30px'
                        }} />
                      </Box>

                    </Grid>
                    <Grid item>
                      <Box component="a" name="TikTok" target="_blank" href="https://www.tiktok.com/@dentalhygienecenter">
                        <TikTokIcon sx={{
                          color: '#CE2227',
                          fontSize: '18px'
                        }} />

                      </Box>
                    </Grid>
                    <Grid item>
                      <Box component="a" name="Youtube" target="_blank" href="https://www.youtube.com/channel/UCeCWny8CT4OBuX5ieLBaPPA">
                        <YoutubeIcon sx={{
                          color: '#CE2227',
                          fontSize: '38px'
                        }} />

                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>

          </Container>

        </Slide>
      </Box>
    </>

  )
}

const query = graphql`
  query MainMenuQuery {
     mainMenu: allPrismicMainMenu {
      edges {
        node {
          type
          lang
          data {
            body {
              ... on PrismicMainMenuDataBodyMenuItem {
                id
                primary {
                  label
                  url
                }
                items {
                  sub_item_label
                  sub_item_url
                }
              }
            }
          }          
        }
      }
    }
  }
`

export default MobileMenu