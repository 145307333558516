import React from "react"
import { Link } from "gatsby"

import Box from '@mui/material/Box';

import logoImg from "../images/logoSmall.jpg"
import { linkResolver } from "../utils/linkResolver";
import { useActiveDoc } from "../contexts/ActiveDocProvider";

const Logo = () => {
    const { activeDoc } = useActiveDoc()

    let logo = <img width="120" height="43"
        src={logoImg}
        alt="Dental Hygiene Center"
    />

    return (
        <Box
            component={Link}
            to={linkResolver({
                type: '',
                lang: activeDoc.lang
            })}
            sx={{
                display: 'block',
                '&:hover': {
                    backgroundColor: 'revert'
                }
            }}>
            {logo}

        </Box>
    )
}

export default Logo
