import React, { useState } from "react"
import { Link } from 'gatsby';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import MenuLink from "./MenuLink";
import { useActiveDoc } from "../../contexts/ActiveDocProvider";

const MenuButton = ({ items, children, sx = [], ...props }) => {
    const [open, setOpen] = useState(false)
    const { activeDoc } = useActiveDoc()

    const isCurrentPage = activeDoc.url === props.to

    const handleMouseEnter = () => {
        setOpen(true)
    }

    const handleMouseLeave = () => {
        setOpen(false)
    }

    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ position: 'relative', height: 47, display: 'flex', alignItems: 'center' }}
        >
            <Box
                component={Link}
                sx={[{
                    textDecoration: "none",
                    fontSize: '18px',
                    color: isCurrentPage ? 'secondary.main' : '#635F5D',
                    fontWeight: 400,
                    display: 'block',
                    textAlign: 'center',
                    '&:hover': {
                        color: 'secondary.main'
                    }
                }, ...(Array.isArray(sx) ? sx : [sx])]}
                {...props}
            >
                {children}
            </Box>
            <Box sx={{
                display: open ? 'block' : 'none',
                position: 'absolute',
                top: 32,
                left: -47,
                padding: 3,
            }}>
                <Box sx={{
                    background: '#fff',
                    borderRadius: '0 0 10px 10px',
                    borderRight: '1px solid #F2F4F7',
                    borderLeft: '1px solid #F2F4F7',
                    borderBottom: '1px solid #F2F4F7',
                    padding: 3,
                    width: 250,
                }}>
                    <Grid
                        container
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="flex-start">
                        {items.map((menuSubItem, idx) => (
                            <Grid item key={`main-menu-sub-item-${idx}`}>
                                <MenuLink
                                    to={menuSubItem.sub_item_url} >
                                    {menuSubItem.sub_item_label}
                                </MenuLink>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default MenuButton
