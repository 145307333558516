exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-page-js": () => import("./../../../src/templates/articles-page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-subscription-js": () => import("./../../../src/templates/subscription.js" /* webpackChunkName: "component---src-templates-subscription-js" */),
  "component---src-templates-subscriptions-page-js": () => import("./../../../src/templates/subscriptions-page.js" /* webpackChunkName: "component---src-templates-subscriptions-page-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */)
}

