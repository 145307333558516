import React from "react"

import SvgIcon from '@mui/material/SvgIcon';

const CloseIcon = props => (
    <SvgIcon {...props} viewBox="0 0 24 25">
        <path d="M18 6.77209L6 18.7721M6 6.77209L18 18.7721" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
)

export default CloseIcon
