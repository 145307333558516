import React from "react"

import SvgIcon from '@mui/material/SvgIcon';

const FacebookIcon = props => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path d="M8.69452 6.937V8.314H7.68652V9.997H8.69452V15H10.7665V9.997H12.1565C12.1565 9.997 12.2875 9.19 12.3505 8.307H10.7745V7.157C10.7745 6.984 11.0005 6.753 11.2245 6.753H12.3525V5H10.8175C8.64352 5 8.69452 6.685 8.69452 6.937Z" />
    <path d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18V18ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z" />
  </SvgIcon>
)

export default FacebookIcon
