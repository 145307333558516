import React, { createContext, useState, useContext } from "react"
import ScheduleModal from "../components/modals/ScheduleModal"

const SchedulerContext = createContext()

export const useScheduler = () => useContext(SchedulerContext)

const SchedulerProvider = ({ children }) => {
    const [open, setOpen] = useState(false)

    const openScheduler = () => {
        setOpen(true)
    }

    const closeScheduler = () => {
        setOpen(false)
    }

    return (
        <SchedulerContext.Provider value={{ openScheduler, closeScheduler, isOpenScheduler: open }}>
            {children}

            <ScheduleModal open={open} />
        </SchedulerContext.Provider>
    )
}

export default SchedulerProvider
