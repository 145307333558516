import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Header from "./Header"
import Footer from './Footer';

import "../styles/custom.scss"

const Layout = ({ children }) => {
    return (
        <>
            <CssBaseline />
            <Header />
            <main css={{
                paddingTop: '65px'
            }}>{children}</main>
            <Footer />
        </>
    )
}

export default Layout