import React from "react"

import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Logo from './Logo'
import MobileMenu from "./menus/MobileMenu";
import SimpleButton from "./buttons/SimpleButton";
import { useMainMenu } from "../contexts/MainMenuProvider";
import Menu from "./menus/Menu";
// import { useScheduler } from "../contexts/SchedulerProvider";
import { useTranslation } from "../contexts/TranslationProvider";


const Header = () => {
    const theme = useTheme();
    const { t } = useTranslation()
    const { isOpenMenu } = useMainMenu()
    // const { openScheduler } = useScheduler()

    return (
        <Box sx={{
            position: 'fixed',
            width: '100%',
            zIndex: 9,
            borderBottom: '1px solid #F2F4F7',
            background: '#fff'
        }}>
            <Container maxWidth="lg" sx={{
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
            }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                        <Logo />
                    </Grid>
                    <Grid item sx={{
                        display: {
                            xs: 'none',
                            md: 'inherit'
                        }
                    }}>
                        <Menu />
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center">
                            {!isOpenMenu && <Grid item>
                                <SimpleButton component="a" href="https://www.planfy.com/booking-widget/medical-advisor-care-srl">{t('schedule')}</SimpleButton>
                            </Grid>}
                            <Grid item sx={{
                                display: {
                                    md: 'none'
                                }
                            }}>
                                <MobileMenu />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Header
