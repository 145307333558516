import React, { createContext, useState, useContext } from "react"

const MainMenuContext = createContext()

export const useMainMenu = () => useContext(MainMenuContext)

const MainMenuProvider = ({ children }) => {
    const [open, setOpen] = useState(false)

    const openMenu = () => {
        setOpen(true)
    }

    const closeMenu = () => {
        setOpen(false)
    }

    return (
        <MainMenuContext.Provider value={{ openMenu, closeMenu, isOpenMenu: open }}>
            {children}
        </MainMenuContext.Provider>
    )
}

export default MainMenuProvider
