import React from "react"
import { Link } from 'gatsby';
import Box from '@mui/material/Box';
import { useActiveDoc } from "../../contexts/ActiveDocProvider";

const MenuLink = ({ children, sx = [], ...props }) => {
    const { activeDoc } = useActiveDoc()
    const isCurrentPage = activeDoc.url === props.to

    return (
        <Box
            component={Link}
            sx={[{
                textDecoration: "none",
                fontSize: '18px',
                color: isCurrentPage ? 'secondary.main' : '#635F5D',
                fontWeight: 400,
                display: 'block',
                textAlign: 'center',
                '&:hover': {
                    color: 'secondary.main'
                }
            }, ...(Array.isArray(sx) ? sx : [sx])]}
            {...props}
        >
            {children}
        </Box>
    )
}

export default MenuLink
