import React, { createContext, useContext } from "react"

const ActiveDocContext = createContext()

export const useActiveDoc = () => useContext(ActiveDocContext)

const ActiveDocProvider = ({ children, pageContext }) => {
    const activeDoc = {
        lang: pageContext.lang || 'ro',
        type: pageContext.type,
        url: pageContext.url,
        uid: pageContext.uid,
        alternateLanguages: pageContext.alternateLanguages ?? [],
    }

    const allLanguages = [{ lang: activeDoc.lang, type: activeDoc.type, uid: activeDoc.uid, url: activeDoc.url, active: true }, ...activeDoc.alternateLanguages]
        .sort((a, b) => (a.lang > b.lang ? -1 : a.lang < b.lang ? 1 : 0))
        .filter(lang => lang.type)

    if (!allLanguages.some(l => l.lang === 'ro')) {
        allLanguages.push({
            active: false,
            lang: 'ro',
            type: null,
            uid: null,
            url: '/ro/'
        })
    }

    activeDoc.allLanguages = allLanguages

    return (
        <ActiveDocContext.Provider value={{ activeDoc }}>
            {children}
        </ActiveDocContext.Provider>
    )
}

export default ActiveDocProvider
