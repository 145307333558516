import React, { useEffect, useState } from "react"
import Box from '@mui/material/Box';
import { useStaticQuery, graphql } from "gatsby"
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useScheduler } from "../../contexts/SchedulerProvider";
import { Grid, Link } from "@mui/material";
import CloseIcon from "../icons/CloseIcon";
import { useActiveDoc } from "../../contexts/ActiveDocProvider";
import PinIcon from "../icons/PinIcon";
import SimpleButton from "../buttons/SimpleButton";
import { useTranslation } from "../../contexts/TranslationProvider";

const ScheduleModal = ({ open }) => {
  const { closeScheduler } = useScheduler()
  const { activeDoc } = useActiveDoc()
  const { t } = useTranslation()
  const [, setModal] = useState(open)

  const handleClose = () => closeScheduler();

  useEffect(() => {
    setModal(open)
  }, [open])

  const queryData = useStaticQuery(query)

  const locations = queryData.locations.edges
    .filter(edge => edge.node.lang === activeDoc.lang)
    .map(r => r.node)


  const [currentLocation, setCurrentLocation] = useState(locations[0])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        border: 'medium none',
        padding: 2
      }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center">
          <Grid item>
            <Typography sx={{
              fontSize: '18px',
              fontWeight: 600
            }}>
              {t('chooseLocation')}
            </Typography>
          </Grid>
          <Grid item>
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: 'pointer',

              }} />
          </Grid>
        </Grid>
        <br />

        <Grid container direction="column" spacing={2}>
          {locations.map(location => <Grid item key={`location-${location.id}`}>
            <Box
              onClick={() => setCurrentLocation(location)}
              sx={{
                borderRadius: '10px',
                border: '1px solid #ccc',
                borderColor: currentLocation.id === location.id ? '#CE2227' : '#ccc',
                height: 80,
                paddingLeft: 4,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: '#CE2227'
                }
              }}>
              <Grid
                container
                alignItems="center"
                spacing={1}>
                <Grid item>
                  <PinIcon sx={{ fontSize: '35px' }} />
                </Grid>
                <Grid item>
                  <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                    {location.data.address}
                  </Typography>
                  <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 300
                  }}>
                    {location.data.city}
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Box>
          </Grid>
          )}
        </Grid>

        <br />
        <SimpleButton
          component={Link}
          href={currentLocation.data.calendar_url}
          target="_blank"
          sx={{
            height: 42,
            color: 'secondary.second',
            backgroundColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.second',
              color: 'secondary.main',
            }
          }} fullWidth>{t('schedule')}</SimpleButton>

      </Box>
    </Modal>
  )
}


const query = graphql`
  query LocationsModalQuery {
    locations: allPrismicLocation(limit: 2) {
        edges {
          node {
            lang
            id
            uid
            data {
              city
              address
              calendar_url
            }
          }
        }
      }
  }
`

export default ScheduleModal
