import React from "react"

import Button from '@mui/material/Button';

const SimpleButton = ({ children, sx = [], ...props }) => {
    return (
        <Button {...props}
            sx={[{
                backgroundColor: '#FFEAEA',
                color: 'primary.main',
                textTransform: 'initial',
                borderRadius: 30,
                WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                padding: '0 12px',
                height: '30px',
                textAlign: 'center',
                fontWeight: 400,
                "&:hover": {
                    backgroundColor: 'secondary.main',
                    color: '#FFEAEA'
                }
            }, ...(Array.isArray(sx) ? sx : [sx])]}
        >
            {children}
        </Button>
    )
}

export default SimpleButton
