import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import "../../styles/menu-burger.scss"
import MenuLink from './MenuLink';


import { useActiveDoc } from "../../contexts/ActiveDocProvider";
import MenuButton from './MenuButton';

const Menu = () => {
  const { activeDoc } = useActiveDoc()

  const data = useStaticQuery(query)

  const mainMenu = data.mainMenu.edges
    .filter(edge => edge.node.lang === activeDoc.lang)
    .map(r => r.node)
    .pop()

  const items = mainMenu.data.body

  const hasSubMenuItems = (menuItem) =>
    menuItem.items.length > 0

  return (
    <>
      <Container>
        <Grid
          container
          direction="row"

          justifyContent="center"
          alignItems="stretch"
        >
          {items.map(menuItem => (
            <Grid item key={menuItem.id}>
              {hasSubMenuItems(menuItem) && <MenuButton
                items={menuItem.items}
                to={menuItem.primary.url}
                sx={{
                  fontWeight: 400,
                  fontSize: '18px'
                }}>
                {menuItem.primary.label}
              </MenuButton>}

              {!hasSubMenuItems(menuItem) && <MenuLink
                to={menuItem.primary.url}
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  height: 47,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 3,

                }}>
                {menuItem.primary.label}
              </MenuLink>}
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

const query = graphql`
  query MenuQuery {
     mainMenu: allPrismicMainMenu {
      edges {
        node {
          type
          lang
          data {
            body {
              ... on PrismicMainMenuDataBodyMenuItem {
                id
                primary {
                  label
                  url
                }     
                items {
                  sub_item_label
                  sub_item_url
                }           
              }
            }
          }          
        }
      }
    }
  }
`

export default Menu