import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'

import { ThemeProvider } from '@mui/material/styles';
import { linkResolver } from './src/utils/linkResolver'
import Layout from './src/components/Layout';

import theme from "./src/styles/theme"
import ActiveDocProvider from './src/contexts/ActiveDocProvider';
import MainMenuProvider from './src/contexts/MainMenuProvider';
import SchedulerProvider from './src/contexts/SchedulerProvider';
import TranslationProvider from './src/contexts/TranslationProvider';
import '@fontsource-variable/lexend';

import i18n from "./src/config/i18n"

export const wrapRootElement = ({ element }) => (
    <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={({ href, ...props }) => (
            <Link to={href} {...props} />
        )}
    >
        <ThemeProvider theme={theme}>
            {element}
        </ThemeProvider>
    </PrismicProvider>
)

export const wrapPageElement = ({ element, props }) => {
    return (
        <ActiveDocProvider pageContext={props.pageContext}>
            <TranslationProvider i18n={i18n}>
                <MainMenuProvider>
                    <SchedulerProvider>
                        <Layout {...props}>{element}</Layout>
                    </SchedulerProvider>
                </MainMenuProvider>
            </TranslationProvider>
        </ActiveDocProvider>
    )
}
